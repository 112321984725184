<script>
    import { labelize } from '@maps/lib/utils'

    export let id
    export let name = id
    export let value
    export let label = labelize(id)
    export let placeholder = labelize(id)
    export let type = 'text'
    export let required = false
    export let errors
    export let min = null
    export let max = null

    $: error = ($errors && $errors[name]) || ''
    $: status = error ? 'has-error' : 'has-success'

    function typeAction (node) {
        node.type = type
    }
</script>

<div class="form-group {status}">
    <div class="col-4 col-sm-12">
        <label class="form-label" for="{id}">{label}</label>
    </div>
    <div class="col-8 col-sm-12">
        <slot {id}>
            <input class="form-input"
                   bind:value
                   use:typeAction
                   {id}
                   {placeholder}
                   {required}
                   {min}
                   {max}
                   >
        </slot>
        <p class="form-input-hint">{error}</p>
    </div>
</div>
